import React from "react"
import { Helmet } from "react-helmet"

//import Layout from "../kdp_theme/Layout"
// import KdpBanner from "../../../components/banner"
import KdpHeader from "../../../components/headers/headerNormalFg"
import KdpContactForm from "../../../components/contacts/contactFormFg"
import KdpFooter from "../../../components/footers/footerv2"


export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Contacter un plombier blicko | Tous types de dépannages plomberie</title>
        <meta name="description" content="Fuite d’eau, bouchon de canalisation, chauffe-eau en panne | Contactez-nous pour recevoir un devis personnalisé | RDV visio plomberie" />
      </Helmet>
      <header>
        {/* <KdpBanner /> */}
        <KdpHeader />
        <KdpContactForm />
        <KdpFooter />
      </header>
      <main>

      </main>
    </div>
  );
}

