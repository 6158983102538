import React from "react"
import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'
import { encode } from 'js-base64';
import LandingForm from "./landingForm"


const encryptedMail= encode("contact@blicko.fr");
const moyen = "Formulaire blicko contact FG";

export default function ContactForm() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Contacter notre plombier blicko</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
            Vous connaissez une galère avec votre installation de plomberie. Fuite d’eau, bouchon de canalisation, chauffe-eau en panne… 
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
            Contactez-nous sans attendre pour obtenir une solution rapide et efficace. 
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
            Soit par téléphone au <a href="tel:0428295269" className="text-blue-500">04 28 29 52 69
            </a>, soit par <a href="https://app.blicko.fr/login" target="_blank" rel="noreferrer" className="text-blue-500">notre application</a> (nous vous le conseillons si vous êtes pressé), soit en remplissant le formulaire ci-dessus 
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p><strong>blicko</strong></p>
                  {/* <p>22 Rue de Chavril</p>
                  <p>69110 Sainte-Foy-Les-Lyon</p> */}
                  <p>12 Rue Dubois</p>
                  <p>69002 Lyon</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Téléphone</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">04 28 29 52 69</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">contact<AtSymbolIcon  className="h-5 inline" />blicko.fr</span>
                </dd>
              </div>
            </dl>
            
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            

            <LandingForm moyen={moyen} destinataire={encryptedMail} />


            <p className="mt-3 text-xs leading-6 text-gray-400">
            Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par blicko visant à traiter votre demande. Elles sont conservées pendant 3 ans et sont destinées au service marketing et au service commercial de blicko. Nos mentions légales sont accessibles ici. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : blicko, Service de la protection des données, 22 Chemin de Charrière Blanche, 69130 Écully. ou par e-mail sur contact<AtSymbolIcon  className="h-5 inline" />blicko.fr en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
